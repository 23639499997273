<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <MyTable
            :fields="overdueFields"
            :items="overdueItems"
            :isShowHeader="false"
            :isFixed="false"
            @handleNoticMsg="handleNoticOverdue"
          ></MyTable>
        </b-card>
      </b-col>
    </b-row>
    <!-- 短信通知model -->
    <b-modal
      id="overdue-model"
      centered
      title="提示"
      ok-only
      ok-title="通知"
      @ok="sendNotice"
    >
      <b-card-text> 是否通知店家即将逾期 </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from "bootstrap-vue";

import MyTable from "@/components/MyTable.vue";

import { getUserInfo } from "@/utils/api";
import { changeTips } from "@/utils/util";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,

    MyTable,
  },
  data() {
    return {
      overdueFields: [
        {
          key: "sid",
          label: "门店id",
        },
        {
          key: "storeName",
          label: "店名",
        },
        {
          key: "address",
          label: "城市地址",
        },
        {
          key: "phone",
          label: "电话",
        },
        {
          key: "serviceLevelCH",
          label: "版本",
        },
        {
          key: "expire_at",
          label: "到期时间",
        },
        {
          key: "SMSAction",
          label: "操作",
        },
      ],
      overdueItems: [],
      versionObj: {
        0: "试用",
        1: "基础",
        2: "专业",
        3: "旗舰",
        4: "品牌",
      },
    };
  },
  created() {
    this.getImmediatelyOverdue();
  },
  methods: {
    //获取即将逾期商户数据列表
    getImmediatelyOverdue() {
      getUserInfo("sysAdminWeb.storeExpiredList", {}).then((res) => {
        // console.log(res);
        if (res && res.data && res.data.code === "200") {
          this.overdueItems = res.data.data;
          this.overdueItems.forEach((it) => {
            it.address = it.addressCity + it.addressDetail;
            it.expire_at = it.expire_at.slice(0, 10);
            it.serviceLevelCH = this.versionObj[it.serviceLevel];
          });
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },
    handleNoticOverdue(e) {
      console.log(e, "发送逾期通知");
      this.currentSMS = e;
      this.$bvModal.show("overdue-model");
    },
    sendNotice() {
      console.log('发送即将逾期通知');
    },
  },
};
</script>

<style>
</style>
